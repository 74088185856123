<template>

  <div class="mainContain">
    <el-tabs v-model="activeName">
      <el-tab-pane label="Basic Information" name="first"><div style=" padding: 0 26px">
        <div style="display: flex; margin: 3vh 0 0 25px;">
          <div style="margin: 0 1vw 0 0.8vw; height: fit-content">
            <div class="userName1" style="width: 130px">User avatar</div>
          </div>
          <div class="Photo">
            <el-upload
                class="avatar-uploader boxCardUploadImg"
                :action="baseURL + '/common/upload'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-error="handleAvatarError"
                accept=".png,.jpg,.jpeg"
            >
              <img
                  :src="form.avatar!==null ? baseURL + form.avatar : avator"
                  class="avatar"
              />
              <!--                  <div class="wrap">-->
              <!--                    <div>上传头像</div>-->
              <!--                  </div>-->
            </el-upload>
          </div>

        </div>
        <div class="information">
          <div class="center">
            <div class="star"><img src="@/assets/star.png"></div>
            <div class="title">User nickname</div>
            <div class="titleInput">
              <el-input
                  v-model="form.gptsUserName"
                  placeholder="Please enter your nickname"
              ></el-input>
            </div>
          </div>
          <div class="center">
            <div class="star"><img src="@/assets/star.png"></div>

            <div class="title">Contact name</div>
            <div class="titleInput">
              <el-input
                  v-model="form.userName"
                  placeholder="Please enter your name"
              ></el-input>
            </div>
          </div>
          <div class="center" >
            <div class="star"><img src="@/assets/star.png"></div>

            <div class="title">Enterprise name</div>
            <div class="titleInput">
              <el-input
                  v-model="form.companyName"
                  placeholder="Please enter your company name"
              ></el-input>
            </div>
          </div>

        </div>
        <div style="display: flex; justify-content: flex-start;margin: 3vh 7vw;text-align: center;width: fit-content">
          <el-button class="cancleStyle" @click="cancleSubmit()"
          >Cancel</el-button
          >
          <div class="confirmStyle" @click="confirmSubmit1()">Confirm</div>
        </div>
      </div></el-tab-pane>
      <el-tab-pane label="Personal Detail Information" name="second"><div style=" padding: 0 26px">
        <div class="information">
          <div class="center" >
            <div class="star"><img src="@/assets/star.png"></div>
            <div class="title">Type of enterprise</div>
            <div class="titleInput">
              <el-cascader
                  :options="industry"
                  v-model="form.companyIndustry"
                  style="width: 100%"
                  placeholder="Please select the type of your enterprise "
              ></el-cascader>
            </div>
          </div>
          <div class="center" >
            <div class="star"><img src="@/assets/star.png"></div>

            <div class="title">Business area</div>
            <div class="titleInput" style="display: flex">
              <el-select v-model="form.country"    @change="countryChange()" style="width: 40%" placeholder="area">
                <el-option

                    v-for="item in gloable"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>

              <div style="background-color: #BCBBBB;height: 3vh;width: 1px;margin-top: 1vh"></div>
              <el-input
                  @input="areaChange"
                  v-model="form.area"
                  max-length="20"
                  placeholder="Please input the business address of your business"
              ></el-input>
            </div>
          </div>
          <div class="center" >
            <div class="star"><img src="@/assets/star.png"></div>
            <div class="title">Contact number</div>
            <div class="titleInput">
              <el-input
                  v-model="form.gptsUserPhone"
                  disabled
                  max-length="20"
                  placeholder="Please enter the phone number"
              ></el-input>
            </div>
          </div>
          <div class="center">
            <div class="star"><img src="@/assets/star.png"></div>
            <div class="title">position</div>
            <div class="titleInput">
              <el-select v-model="form.job" style="width: 100%" placeholder="Please select your position style">
                <el-option
                    v-for="item in jobStatus"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>

        </div>
        <div style="display: flex; justify-content: flex-start;margin: 3vh 7vw;text-align: center;width: fit-content">
          <el-button class="cancleStyle" @click="cancleSubmit()"
          >Cancel</el-button
          >
          <div class="confirmStyle" @click="confirmSubmit2()">Confirm</div>
        </div>
      </div></el-tab-pane>

    </el-tabs>
  </div>

</template>


<script>

import {proCityList} from "@/api/city";

export default {

  name: "",

  props: [],

  components: {},

  data() {

    return {
      provinces: proCityList,
      baseURL: this.$utils.baseURL,
      avator: require("@/assets/user.jpg"),
      form:[],
      industry:[],
      input:'',
      userId:localStorage.getItem("userId"),
      // userId:48
      activeName:'first',
      jobStatus:[
        { value: '公司负责人',
          label: 'Company leader'},
        { value: '采购人员',
          label: 'Procurement'},
        { value: '销售人员',
          label: 'Sale'},
        { value: '技术人员',
          label: 'Technology'},
        { value: '其他人员',
          label: 'Other'},

      ],
      gloable:[
        { value: '中国',
          label: 'China'},
        { value: '欧洲',
          label: 'Europe'},
        { value: '南美',
          label: 'South America'},
        { value: '北美',
          label: 'North America'},
        { value: '中亚',
          label: 'Central Asia'},
        { value: '澳洲',
          label: 'Australia'},
        { value: '东南亚',
          label: 'Southeast Asia'},
        { value: '中东',
          label: 'Middle East'},
        { value: '其他地区',
          label: 'Other regions'},
      ],
    }

  },
  created() {
    this.jobCitythis()
    this.getUser()
    this.getIndustry()
  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    countryChange(){
      console.log(this.form.country)
      this.$forceUpdate()
    },
    areaChange(){
      console.log(this.form.area)
      this.$forceUpdate()
    },
    handleAvatarSuccess(res) {
      this.form.avatar = res.fileName;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Upload picture size cannot exceed 2MB!");
      }
      return isLt2M;
    },
    // 文件上传失败（http）
    handleAvatarError() {
      this.$message.error("Upload failed! (http failed)");
    },
    jobCitythis(){
      this.provinces=this.transformCityData(this.provinces);
    },
    transformCityData(data) {
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.name,
          label: item.name,
          children: item.children ? this.transformCityDataChild(item.children) : []
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    transformCityDataChild(data) {
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.name,
          label: item.name,
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    cancleSubmit() {
      this.getUser()
    },
    confirmSubmit1() {
      if (!this.form.gptsUserName) {
        this.$message.error("Please enter your nickname");
        return false;
      }
      if (!this.form.userName) {
        this.$message.error("Please enter your user name");
        return false;
      }
      if (!this.form.companyName) {
        this.$message.error("Please enter your company name");
        return false;
      }

      this.$axios
          .put("/info/gpts_user", {
            companyName: this.form.companyName,
            gptsUserEmail: this.form.gptsUserEmail,
            gptsUserId: this.userId,
            gptsUserName: this.form.gptsUserName,
            avatar:this.form.avatar,
            gptsUserPhone: this.form.gptsUserPhone,
            job:this.form.job,
            userName: this.form.userName
          }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            console.log(res);
            this.$message({
              showClose: true,
              message: 'Your personal information has been submitted successfully',
              type: 'success'
            });
            this.$forceUpdate()
            this.getUser()
            this.activeName='second'
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              showClose: true,
              message: 'The submission failed due to network reasons. Please try again later',
              type: 'success'
            });
          });

    },
    confirmSubmit2() {
      if (!this.form.companyIndustry) {
        this.$message.error("Please enter the business type of your company");
        return false;
      }
      if (!this.form.job) {
        this.$message.error("Please select the type of your job");
        return false;
      }


      const cityNow=this.form.country+"-"+this.form.area
      const companyIndustryNow=this.form.companyIndustry[0]+"-"+this.form.companyIndustry[1]
      if (!cityNow) {
        this.$message.error("Please enter the address of your company");
        return false;
      }
      this.$axios
          .put("/info/gpts_user", {
            city: cityNow,
            companyIndustry: companyIndustryNow,
            companyName: this.form.companyName,
            gptsUserEmail: this.form.gptsUserEmail,
            gptsUserId: this.userId,
            gptsUserName: this.form.gptsUserName,
            avatar:this.form.avatar,
            gptsUserPhone: this.form.gptsUserPhone,
            job:this.form.job,
            userName: this.form.userName
          }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            console.log(res);
            this.$message({
              showClose: true,
              message: 'Your personal information has been submitted successfully',
              type: 'success'
            });
            this.getUser()
            this.$router.push("/")
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              showClose: true,
              message: 'The submission failed due to network reasons. Please try again later',
              type: 'success'
            });
          });

    },
    getIndustry(){
      this.$axios
          .get("/info/industry/list",{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.industry = this.transformIndustryData(res.data.data)

            // console.log("--------ren",this.industry)

          })
          .catch((err) => {
            console.log(err);
          });
    },
    transformIndustryData(data) {
      // console.log(data)
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.industryContentEn,
          label: item.industryContentEn,
          children: item.child ? this.transformIndustryDataChild(item.child) : []
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    transformIndustryDataChild(data) {
      // console.log(data)
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.industryContentEn,
          label: item.industryContentEn,
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    getUser() {
      this.$axios
          .get("/info/gpts_user/" + this.userId)
          .then((res) => {
            this.form = res.data.data;
            var str = this.form.city
            var parts = str.split("-");
            this.form.country=parts[0]
            this.form.area=parts[1]
            // this.form.city[1]=parts[1]
            var strcompanyIndustry = this.form.companyIndustry
            var partscompanyIndustry = strcompanyIndustry.split("-");
             this.form.companyIndustry=partscompanyIndustry

            console.log("--------ren",this.form)

          })
          .catch((err) => {
            console.log(err);
          });
    },
  },

  mounted() {

  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.mainContain{
  width: 65vw;
  border-radius: 9px;
  margin:0 1vw;
  background-color: white;
  height: 75vh;
  padding: 4vh 2vw 3vh 0;
  position: relative;
  background-image:url("../../assets/oilba.png");
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  background-color: transparent;

  ::v-deep .el-input__prefix {
    left: 90%;
    transition: all 0.3s;
  }
  ::v-deep .el-input.is-disabled .el-input__inner {
    background-color: white !important;
    //border: #575757 1px solid !important;
    //color: #c0c4cc;
    border: none;
    cursor: not-allowed;
  }

  ::v-deep .el-input .el-input__inner {
    background-color: white !important;
    //border: #575757 1px solid !important;
    //color: #c0c4cc;
    border:none;
    height: 4.8vh;
    padding: 0 8px;
  }
  .cancleStyle {
    margin-top: 1vh;
    width: 9vw;
    height: 6vh;
    border-radius: 9px;
    opacity: 1;
    border: 1px solid #bf0022;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #bf0022;
    line-height: 20px;
    -webkit-background-clip: text;
  }
  .confirmStyle {
    margin-top: 1vh;
    width: 9vw;
    height: 6vh;
    border-radius: 9px;
    margin-left: 2vw;
    cursor: pointer;
    border: none;
    background-color: #bf0022;
    font-size: 16px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
    position: relative;
  }

  ::v-deep .el-input--prefix .el-input__inner {
    padding-left: 15px;

  }
  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-input__suffix {
    height: 100%;
    right:3px;
    transition: all 0.3s;
    pointer-events: none;
  }

  .information {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    column-gap: 2vw;
    row-gap: 2vh;
    padding: 3vh 1vw;
    .center{
      width: 70%;
      display: flex;
      //justify-content: center;
      align-items: center
    }
    .star{
      width: 1vh;
      height:1vh;
      margin-top: -2vh;
      margin-right: 1vh;
    }
    .title {
      height: fit-content;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      width: 130px;
      line-height: 1.5vh;
      -webkit-background-clip: text;
    }
    .titleInput {
      border:1px solid #BCBBBB;
      border-radius: 5px;
      margin: 1vh 1vw;
      padding: 2px;
      width: 60%;

    }
  }
  .editStyle {
    position: absolute;
    top: 0;
    display: flex;
    right: 0;
    height: 12px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    cursor: pointer;
    .editLogo {
      width: 13px;
      height: 12px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .editTitle {
      width: 28px;
      height: 16px;
      font-size: 14px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #bf0022;
      line-height: 16px;
      margin-left: 7px;
    }
  }

  .Photo {
    width: 3vw;
    height:3vw;
    margin-left: 5px;
    border-radius: 50%;
    opacity: 1;
  }
  .avatar {
    border-radius: 50%;
  }

  .userName {
    height: 5vh;
    font-size: 1vw;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: #333333;
    line-height: 5vh;
  }
  .userName1 {
    margin-top: 1vh;
    width:fit-content;
    height: 2vh;;
    font-size: 16px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #000000;
    line-height: 1.5vh;
    //color: #bf0022;
    -webkit-background-clip: text;
  }
  .userName2 {
    margin-top: 1vh;
    width: fit-content;
    color: gray;
    font-size: 16px;
    height: 24px;
    opacity: 1;
  }
  .userSex {
    height: 16px;
    font-size: 16px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    margin-left: 6px;
    margin-right: 32px;
  }
  .iconStyle {
    width: 14px;
    height: 15px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }

}
:deep .el-tabs__nav-scroll{
  padding:0 3vw;
}
:deep .el-tabs__item{
  height: 50px;
}


</style>
