export const proCityList = [
    {
        code: '110000',
        name: 'Beijing',
        children: [
            {
                code: '110101',
                name: 'Dongcheng District'
            },
            {
                code: '110102',
                name: 'Xicheng District'
            },
            {
                code: '110105',
                name: 'Chaoyang District'
            },
            {
                code: '110106',
                name: 'Fengtai District'
            },
            {
                code: '110107',
                name: 'Shijingshan District'
            },
            {
                code: '110108',
                name: 'Haidian District'
            },
            {
                code: '110109',
                name: 'Mentougou District'
            },
            {
                code: '110111',
                name: 'Fangshan District'
            },
            {
                code: '110112',
                name: 'Tongzhou District'
            },
            {
                code: '110113',
                name: 'Shunyi District'
            },
            {
                code: '110114',
                name: 'Changping District'
            },
            {
                code: '110115',
                name: 'Daxing District'
            },
            {
                code: '110116',
                name: 'Huairou District'
            },
            {
                code: '110117',
                name: 'Pinggu District'
            },
            {
                code: '110200',
                name: 'Tianjin Municipal County',
                children: [
                    {
                        code: '110228',
                        name: "Miyun County"
                    },
                    {
                        code: '110229',
                        name: "Yanqing County"
                    }
                ]
            },
        ]
    },
    {
        code: '120000',
        name: 'Tianjin',
        children: [
            {
                code: '120101',
                name: 'Heping District'
            },
            {
                code: '120102',
                name: 'Hedong District'
            },
            {
                code: '120103',
                name: 'Hexi District'
            },
            {
                code: '120104',
                name: 'Nankai District'
            },
            {
                code: '120105',
                name: 'Hebei District'
            },
            {
                code: '120106',
                name: 'Hongqiao District'
            },
            {
                code: '120110',
                name: 'Dongli District'
            },
            {
                code: '120111',
                name: 'Xiqing District'
            },
            {
                code: '120112',
                name: 'Jinnan District'
            },
            {
                code: '120113',
                name: 'Beichen District'
            },
            {
                code: '120114',
                name: 'Wuqing District'
            },
            {
                code: '120115',
                name: 'Baodi District'
            },
            {
                code: '120116',
                name: 'Binhaixin District'
            },

            {
                code: '120200',
                name: 'Tianjin Municipal County',
                children: [
                    {
                        code: '120221',
                        name: 'Ninghe County'
                    },
                    {
                        code: '120223',
                        name: 'Jinghai County'
                    },
                    {
                        code: '120225',
                        name: 'Jixian County' // Note: Jixian is the old name for Ji County. The official name in English is now "Ji County".
                    }
                ]
            }
        ]
    },
    {
        "code": "130000",
        "name": "Hebei Province",
        "children": [
            {
                "code": "130100",
                "name": "Shijiazhuang City"
            },
            {
                "code": "130200",
                "name": "Tangshan City"
            },
            {
                "code": "130300",
                "name": "Qinhuangdao City"
            },
            {
                "code": "130400",
                "name": "Handan City"
            },
            {
                "code": "130500",
                "name": "Xingtai City"
            },
            {
                "code": "130600",
                "name": "Baoding City"
            },
            {
                "code": "130700",
                "name": "Zhangjiakou City"
            },
            {
                "code": "130800",
                "name": "Chengde City"
            },
            {
                "code": "130900",
                "name": "Cangzhou City"
            },
            {
                "code": "131000",
                "name": "Langfang City"
            },
            {
                "code": "131100",
                "name": "Hengshui City"
            }
        ]
    },
    {
        "code": "140000",
        "name": "Shanxi Province",
        "children": [
            {
                "code": "140100",
                "name": "Taiyuan City"
            },
            {
                "code": "140200",
                "name": "Datong City"
            },
            {
                "code": "140300",
                "name": "Yangquan City"
            },
            {
                "code": "140400",
                "name": "Changzhi City"
            },
            {
                "code": "140500",
                "name": "Jincheng City"
            },
            {
                "code": "140600",
                "name": "Shuozhou City"
            },
            {
                "code": "140700",
                "name": "Jinzhong City"
            },
            {
                "code": "140800",
                "name": "Yuncheng City"
            },
            {
                "code": "140900",
                "name": "Xinzhou City"
            },
            {
                "code": "141000",
                "name": "Linfen City"
            },
            {
                "code": "141100",
                "name": "Lvliang City"
            }
        ]
    },
    {
        "code": "150000",
        "name": "Inner Mongolia Autonomous Region",
        "children": [
            {
                "code": "150100",
                "name": "Hohhot (Huhehaote) City"
            },
            {
                "code": "150200",
                "name": "Baotou City"
            },
            {
                "code": "150300",
                "name": "Wuhai City"
            },
            {
                "code": "150400",
                "name": "Chifeng City"
            },
            {
                "code": "150500",
                "name": "Tongliao City"
            },
            {
                "code": "150600",
                "name": "Ordos (Erdos) City"
            },
            {
                "code": "150700",
                "name": "Hulunbuir (Hulunbeier) City"
            },
            {
                "code": "150800",
                "name": "Bayannur (Bayannao'er) City"
            },
            {
                "code": "150900",
                "name": "Ulanqab (Wulanchabu) City"
            },
            {
                "code": "152200",
                "name": "Xing'an League"
            },
            {
                "code": "152500",
                "name": "Xilingol (Xilin Gol) League"
            },
            {
                "code": "152900",
                "name": "Alashan (Alxa) League"
            }
        ]
    },
    {
        "code": "210000",
        "name": "Liaoning Province",
        "children": [
            {
                "code": "210100",
                "name": "Shenyang City"
            },
            {
                "code": "210200",
                "name": "Dalian City"
            },
            {
                "code": "210300",
                "name": "Anshan City"
            },
            {
                "code": "210400",
                "name": "Fushun City"
            },
            {
                "code": "210500",
                "name": "Benxi City"
            },
            {
                "code": "210600",
                "name": "Dandong City"
            },
            {
                "code": "210700",
                "name": "Jinzhou City"
            },
            {
                "code": "210800",
                "name": "Yingkou City"
            },
            {
                "code": "210900",
                "name": "Fuxin City"
            },
            {
                "code": "211000",
                "name": "Liaoyang City"
            },
            {
                "code": "211100",
                "name": "Panjin City"
            },
            {
                "code": "211200",
                "name": "Tieling City"
            },
            {
                "code": "211300",
                "name": "Chaoyang City"
            },
            {
                "code": "211400",
                "name": "Huludao City"
            }
        ]
    },
    {
        "code": "220000",
        "name": "Jilin Province",
        "children": [
            {
                "code": "220100",
                "name": "Changchun City"
            },
            {
                "code": "220200",
                "name": "Jilin City"
            },
            {
                "code": "220300",
                "name": "Siping City"
            },
            {
                "code": "220400",
                "name": "Liaoyuan City"
            },
            {
                "code": "220500",
                "name": "Tonghua City"
            },
            {
                "code": "220600",
                "name": "Baishan City"
            },
            {
                "code": "220700",
                "name": "Songyuan City"
            },
            {
                "code": "220800",
                "name": "Baicheng City"
            },
            {
                "code": "222400",
                "name": "Yanbian Korean Autonomous Prefecture"
            }
        ]
    },
    {
        "code": "230000",
        "name": "Heilongjiang Province",
        "children": [
            {
                "code": "230100",
                "name": "Harbin City"
            },
            {
                "code": "230200",
                "name": "Qiqihar City"
            },
            {
                "code": "230300",
                "name": "Jixi City"
            },
            {
                "code": "230400",
                "name": "Hegang City"
            },
            {
                "code": "230500",
                "name": "Shuangyashan City"
            },
            {
                "code": "230600",
                "name": "Daqing City"
            },
            {
                "code": "230700",
                "name": "Yichun City"
            },
            {
                "code": "230800",
                "name": "Jiamusi City"
            },
            {
                "code": "230900",
                "name": "Qitaihe City"
            },
            {
                "code": "231000",
                "name": "Mudanjiang City"
            },
            {
                "code": "231100",
                "name": "Heihe City"
            },
            {
                "code": "231200",
                "name": "Suihua City"
            },
            {
                "code": "232700",
                "name": "Daxing'anling Area"
            }
        ]
    },
    {
        "code": "310000",
        "name": "Shanghai",
        "children": [
            {
                "code": "310101",
                "name": "Huangpu District"
            },
            {
                "code": "310104",
                "name": "Xuhui District"
            },
            {
                "code": "310105",
                "name": "Changning District"
            },
            {
                "code": "310106",
                "name": "Jingan District"
            },
            {
                "code": "310107",
                "name": "Putuo District"
            },
            {
                "code": "310108",
                "name": "Zhabei District"
            },
            {
                "code": "310109",
                "name": "Hongkou District"
            },
            {
                "code": "310110",
                "name": "Yangpu District"
            },
            {
                "code": "310112",
                "name": "Minhang District"
            },
            {
                "code": "310113",
                "name": "Baoshan District"
            },
            {
                "code": "310114",
                "name": "Jiading District"
            },
            {
                "code": "310115",
                "name": "Pudong New Area"
            },
            {
                "code": "310116",
                "name": "Jinshan District"
            },
            {
                "code": "310117",
                "name": "Songjiang District"
            },
            {
                "code": "310118",
                "name": "Qingpu District"
            },
            {
                "code": "310120",
                "name": "Fengxian District"
            }
        ]
    },
    {
        "code": "320000",
        "name": "Jiangsu Province",
        "children": [
            {
                "code": "320100",
                "name": "Nanjing City"
            },
            {
                "code": "320200",
                "name": "Wuxi City"
            },
            {
                "code": "320300",
                "name": "Xuzhou City"
            },
            {
                "code": "320400",
                "name": "Changzhou City"
            },
            {
                "code": "320500",
                "name": "Suzhou City"
            },
            {
                "code": "320600",
                "name": "Nantong City"
            },
            {
                "code": "320700",
                "name": "Lianyungang City"
            },
            {
                "code": "320800",
                "name": "Huai'an City"
            },
            {
                "code": "320900",
                "name": "Yancheng City"
            },
            {
                "code": "321000",
                "name": "Yangzhou City"
            },
            {
                "code": "321100",
                "name": "Zhenjiang City"
            },
            {
                "code": "321200",
                "name": "Taizhou City"
            },
            {
                "code": "321300",
                "name": "Suqian City"
            }
        ]
    },
    {
        "code": "330000",
        "name": "Zhejiang Province",
        "children": [
            {
                "code": "330100",
                "name": "Hangzhou City"
            },
            {
                "code": "330200",
                "name": "Ningbo City"
            },
            {
                "code": "330300",
                "name": "Wenzhou City"
            },
            {
                "code": "330400",
                "name": "Jiaxing City"
            },
            {
                "code": "330500",
                "name": "Huzhou City"
            },
            {
                "code": "330600",
                "name": "Shaoxing City"
            },
            {
                "code": "330700",
                "name": "Jinhua City"
            },
            {
                "code": "330800",
                "name": "Quzhou City"
            },
            {
                "code": "330900",
                "name": "Zhoushan City"
            },
            {
                "code": "331000",
                "name": "Taizhou City"
            },
            {
                "code": "331100",
                "name": "Lishui City"
            }
        ]
    },
    {
        "code": "340000",
        "name": "Anhui Province",
        "children": [
            {"code": "340100","name": "Hefei City"},
            {"code": "340200",  "name": "Wuhu City"},
            {"code": "340300",  "name": "Bengbu City"},
            {"code": "340400",  "name": "Huainan City"},
            {"code": "340500",  "name": "Ma'anshan City"},
            {"code": "340600",  "name": "Huaibei City"},
            {"code": "340700",  "name": "Tongling City"},
            {"code": "340800",  "name": "Anqing City"},
            {"code": "341000",  "name": "Huangshan City"},
            {"code": "341100",  "name": "Chuzhou City"},
            {"code": "341200",  "name": "Fuyang City"},
            {"code": "341300",  "name": "Suzhou City"},
            {"code": "341400",  "name": "Chaohu City"},
            {"code": "341500",  "name": "Lu'an City"},
            {"code": "341600",  "name": "Bozhou City"},
            {"code": "341700",  "name": "Chizhou City"},
            {"code": "341800",  "name": "Xuancheng City"}
        ]
    },
    {
        "code": "350000",
        "name": "Fujian Province",
        "children": [
            {"code": "350100",  "name": "Fuzhou City"},
            {"code": "350200",  "name": "Xiamen City"},
            {"code": "350300",  "name": "Putian City"},
            {"code": "350400",  "name": "Sanming City"},
            {"code": "350500",  "name": "Quanzhou City"},
            {"code": "350600",  "name": "Zhangzhou City"},
            {"code": "350700",  "name": "Nanping City"},
            {"code": "350800",  "name": "Longyan City"},
            {"code": "350900",  "name": "Ningde City"}
        ]
    },
    {
        "code": "360000",
        "name": "Jiangxi Province",
        "children": [
            {"code": "360100",  "name": "Nanchang City"},
            {"code": "360200",  "name": "Jingdezhen City"},
            {"code": "360300",  "name": "Pingxiang City"},
            {"code": "360400",  "name": "Jiujiang City"},
            {"code": "360500",  "name": "Xinyu City"},
            {"code": "360600",  "name": "Yingtan City"},
            {"code": "360700",  "name": "Ganzhou City"},
            {"code": "360800",  "name": "Ji'an City"},
            {"code": "360900",  "name": "Yichun City"},
            {"code": "361000",  "name": "Fuzhou City"},
            {"code": "361100",  "name": "Shangrao City"}
        ]
    },
    {
        "code": "370000",
        "name": "Shandong Province",
        "children": [
            {"code": "370100",  "name": "Jinan City"},
            {"code": "370200",  "name": "Qingdao City"},
            {"code": "370300",  "name": "Zibo City"},
            {"code": "370400",  "name": "Zaozhuang City"},
            {"code": "370500",  "name": "Dongying City"},
            {"code": "370600",  "name": "Yantai City"},
            {"code": "370700",  "name": "Weifang City"},
            {"code": "370800",  "name": "Jining City"},
            {"code": "370900",  "name": "Tai'an City"},
            {"code": "371000",  "name": "Weihai City"},
            {"code": "371100", "name": "Rizhao City"},
            {"code": "371200", "name": "Laiwu City"},
            {"code": "371300", "name": "Linyi City"},
            {"code": "371400", "name": "Dezhou City"},
            {"code": "371500", "name": "Liaocheng City"},
            {"code": "371600", "name": "Binzhou City"},
            {"code": "371700", "name": "Heze City"}
        ]
    },
    {
        "code": "410000",
        "name": "Henan Province",
        "children": [
            {"code": "410100",  "name": "Zhengzhou City"},
            {"code": "410200",  "name": "Kaifeng City"},
            {"code": "410300", "name": "Luoyang City"},
            {"code": "410400",  "name": "Pingdingshan City"},
            {"code": "410500",  "name": "Anyang City"},
            {"code": "410600",  "name": "Hebi City"},
            {"code": "410700",  "name": "Xinxiang City"},
            {"code": "410800",  "name": "Jiaozuo City"},
            {"code": "410900",  "name": "Puyang City"},
            {"code": "411000",  "name": "Xuchang City"},
            {"code": "411100",  "name": "Luohe City"},
            {"code": "411200",  "name": "Sanmenxia City"},
            {"code": "411300",  "name": "Nanyang City"},
            {"code": "411400",  "name": "Shangqiu City"},
            {"code": "411500",  "name": "Xinyang City"},
            {"code": "411600",  "name": "Zhoukou City"},
            {"code": "411700",  "name": "Zhumadian City"},
            {"code": "419000", "name": "Henan Province Directly Administered County-Level Administrative Division"}
        ]
    },
    {
        "code": "420000",
        "name": "Hubei Province",
        "children": [
            {"code": "420100",  "name": "Wuhan City"},
            {"code": "420200",  "name": "Huangshi City"},
            {"code": "420300",  "name": "Shiyan City"},
            {"code": "420500",  "name": "Yichang City"},
            {"code": "420600",  "name": "Xiangyang City"},  // Note: 襄樊市 is now officially known as 襄阳市 (Xiangyang City)
            {"code": "420700",  "name": "Ezhou City"},
            {"code": "420800",  "name": "Jingmen City"},
            {"code": "420900",  "name": "Xiaogan City"},
            {"code": "421000",  "name": "Jingzhou City"},
            {"code": "421100",  "name": "Huanggang City"},
            {"code": "421200",  "name": "Xianning City"},
            {"code": "421300",  "name": "Suizhou City"},
            {"code": "422800",  "name": "Enshi Tujia and Miao Autonomous Prefecture"}
        ]
    },
    {
        "code": "430000",
        "name": "Hunan Province",
        "children": [
            {"code": "430100",  "name": "Changsha City"},
            {"code": "430200",  "name": "Zhuzhou City"},
            {"code": "430300",  "name": "Xiangtan City"},
            {"code": "430400",  "name": "Hengyang City"},
            {"code": "430500",  "name": "Shaoyang City"},
            {"code": "430600",  "name": "Yueyang City"},
            {"code": "430700",  "name": "Changde City"},
            {"code": "430800",  "name": "Zhangjiajie City"},
            {"code": "430900",  "name": "Yiyang City"},
            {"code": "431000",  "name": "Chenzhou City"},
            {"code": "431100",  "name": "Yongzhou City"},
            {"code": "431200",  "name": "Huaihua City"},
            {"code": "431300",  "name": " Loudi City"},
            {"code": "433100",  "name": "Xiangxi Tujia and Miao Autonomous Prefecture"}
        ]
    },
    {
        "code": "440000",
        "name": "Guangdong Province",
        "children": [
            {"code": "440100", "name": "Guangzhou City"},
            {"code": "440200", "name": "Shaoguan City"},
            {"code": "440300", "name": "Shenzhen City"},
            {"code": "440400", "name": "Zhuhai City"},
            {"code": "440500", "name": "Shantou City"},
            {"code": "440600", "name": "Foshan City"},
            {"code": "440700", "name": "Jiangmen City"},
            {"code": "440800", "name": "Zhanjiang City"},
            {"code": "440900", "name": "Maoming City"},
            {"code": "441200", "name": "Zhaoqing City"},
            {"code": "441300", "name": "Huizhou City"},
            {"code": "441400", "name": "Meizhou City"},
            {"code": "441500", "name": " Shanwei City"},
            {"code": "441600", "name": "Heyuan City"},
            {"code": "441700", "name": "Yangjiang City"},
            {"code": "441800", "name": "Qingyuan City"},
            {"code": "441900", "name": "Dongguan City"},
            {"code": "442000", "name": "Zhongshan City"},
            {"code": "445100", "name": "Chaozhou City"},
            {"code": "445200",  "name": "Jieyang City"},
            {"code": "445300",  "name": "Yunfu City"}
        ]
    },
    {
        "code": "450000",
        "name": "Guangxi Zhuang Autonomous Region",
        "children": [
            {"code": "450100",  "name": "Nanning City"},
            {"code": "450200",  "name": "Liuzhou City"},
            {"code": "450300",  "name": "Guilin City"},
            {"code": "450400",  "name": "Wuzhou City"},
            {"code": "450500",  "name": "Beihai City"},
            {"code": "450600", "name": "Fangchenggang City"},
            {"code": "450700",  "name": "Qinzhou City"},
            {"code": "450800",  "name": "Guigang City"},
            {"code": "450900",  "name": "Yulin City"},
            {"code": "451000",  "name": "Baise City"},
            {"code": "451100",  "name": "Hezhou City"},
            {"code": "451200",  "name": "Hechi City"},
            {"code": "451300",  "name": "Laibin City"},
            {"code": "451400",  "name": "Chongzuo City"}
        ]
    },
    {
        "code": "460000",
        "name": "Hainan Province",
        "children": [
            {"code": "460100",  "name": "Haikou City"},
            {"code": "460200",  "name": "Sanya City"}
        ]
    },
    {
        "code": "500000",
        "name": "Chongqing Municipality",
        "children": [
            {"code": "500101",  "name": "Wanzhou District"},
            {"code": "500102",  "name": "Fuling District"},
            {"code": "500103",  "name": "Yuzhong District"},
            {"code": "500104",  "name": "Dadukou District"},
            {"code": "500105",  "name": "Jiangbei District"},
            {"code": "500106",  "name": "Shapingba District"},
            {"code": "500107",  "name": "Jiulongpo District"},
            {"code": "500108",  "name": "Nanan District"},
            {"code": "500109",  "name": "Beibei District"},
            {"code": "500110",  "name": "Qijiang District"},
            {"code": "500111",  "name": "Dazu District"},
            {"code": "500112",  "name": "Yubei District"},
            {"code": "500113",  "name": "Banan District"},
            {"code": "500114",  "name": "Qianjiang District"},
            {"code": "500115",  "name": "Changshou District"},
            {"code": "500116",  "name": "Jiangjin District"},
            {"code": "500117",  "name": "Hechuan District"},
            {"code": "500118",  "name": "Yongchuan District"},
            {"code": "500119",  "name": "Nanchuan District"},
            {"code": "500120",  "name": "Bishan District"},
            {"code": "500151",  "name": "Tongliang District"}
        ]
    },
    {
        "code": "510000",
        "name": "Sichuan Province",
        "children": [
            {"code": "510100",  "name": "Chengdu City"},
            {"code": "510300",  "name": "Zigong City"},
            {"code": "510400",  "name": "Panzhihua City"},
            {"code": "510500",  "name": "Luzhou City"},
            {"code": "510600",  "name": "Deyang City"},
            {"code": "510700",  "name": "Mianyang City"},
            {"code": "510800",  "name": "Guangyuan City"},
            {"code": "510900",  "name": "Suining City"},
            {"code": "511000",  "name": "Neijiang City"},
            {"code": "511100",  "name": "Leshan City"},
            {"code": "511300",  "name": "Nanchong City"},
            {"code": "511400",  "name": "Meishan City"},
            {"code": "511500",  "name": "Yibin City"},
            {"code": "511600",  "name": "Guangan City"},
            {"code": "511700",  "name": "Dazhou City"},
            {"code": "511800",  "name": "Ya'an City"},
            {"code": "511900",  "name": "Bazhong City"},
            {"code": "512000",  "name": "Ziyang City"},
            {"code": "513200",  "name": "Ngawa Tibetan and Qiang Autonomous Prefecture"},
            {"code": "513300",  "name": "Garzê Tibetan Autonomous Prefecture"},
            {"code": "513400",  "name": "Liángshān Yi Autonomous Prefecture"}
        ]
    },
    {
        "code": "520000",
        "name": "Guizhou Province",
        "children": [
            {"code": "520100", "name": "Guiyang City"},
            {"code": "520200", "name": "Liupanshui City"},
            {"code": "520300", "name": "Zunyi City"},
            {"code": "520400", "name": "Anshun City"},
            {"code": "522200", "name": "Tongren District"},
            {"code": "522300", "name": "Qiannan Buyi and Miao Autonomous Prefecture"},
            {"code": "522400", "name": "Bijie District"},
            {"code": "522600", "name": "Qiandongnan Miao and Dong Autonomous Prefecture"},
            {"code": "522700", "name": "Qiannan Buyi and Miao Autonomous Prefecture"}
        ]
    },
    {
        "code": "530000",
        "name": "Yunnan Province",
        "children": [
            {"code": "530100",  "name": "Kunming City"},
            {"code": "530300",  "name": "Qujing City"},
            {"code": "530400",  "name": "Yuxi City"},
            {"code": "530500",  "name": "Baoshan City"},
            {"code": "530600",  "name": "Zhaotong City"},
            {"code": "530700",  "name": "Lijiang City"},
            {"code": "530800",  "name": "Simao City"}, // Note: Simao City was renamed to Pu'er City in 2007.
            {"code": "530900",  "name": "Lincang City"},
            {"code": "532300",  "name": "Chuxiong Yi Autonomous Prefecture"},
            {"code": "532500",  "name": "Honghe Hani and Yi Autonomous Prefecture"},
            {"code": "532600",  "name": "Wenshan Zhuang and Miao Autonomous Prefecture"},
            {"code": "532800",  "name": "Xishuangbanna Dai Autonomous Prefecture"},
            {"code": "532900",  "name": "Dali Bai Autonomous Prefecture"},
            {"code": "533100",  "name": "Dehong Dai and Jingpo Autonomous Prefecture"},
            {"code": "533300",  "name": "Nujiang Lisu Autonomous Prefecture"},
            {"code": "533400",  "name": "Shangri-La Tibetan Autonomous Prefecture"}
        ]
    },
    {
        "code": "540000",
        "name": "Tibet Autonomous Region",
        "children": [
            {"code": "540100", "name": "Lhasa City"},
            {"code": "542100",  "name": "Qamdo District"},
            {"code": "542200",  "name": "Shannan District"},
            {"code": "542300",  "name": "Xigaze District"},
            {"code": "542400",  "name": "Nagqu District"},
            {"code": "542500",  "name": "Ngari District"},
            {"code": "542600",  "name": "Nyingchi District"}
        ]
    },
    {
        "code": "610000",
        "name": "Shaanxi Province",
        "children": [
            {"code": "610100",  "name": "Xi'an City"},
            {"code": "610200",  "name": "Tongchuan City"},
            {"code": "610300",  "name": "Baoji City"},
            {"code": "610400",  "name": "Xianyang City"},
            {"code": "610500",  "name": "Weinan City"},
            {"code": "610600",  "name": "Yan'an City"},
            {"code": "610700",  "name": "Hanzhong City"},
            {"code": "610800",  "name": "Yulin City"},
            {"code": "610900",  "name": " Ankang City"},
            {"code": "611000",  "name": "Shangluo City"}
        ]
    },
    {
        "code": "620000",
        "name": "Gansu Province",
        "children": [
            {"code": "620100",  "name": "Lanzhou City"},
            {"code": "620200",  "name": "Jiayuguan City"},
            {"code": "620300",  "name": "Jinchang City"},
            {"code": "620400",  "name": "Baiyin City"},
            {"code": "620500",  "name": "Tianshui City"},
            {"code": "620600",  "name": "Wuwei City"},
            {"code": "620700",  "name": "Zhangye City"},
            {"code": "620800",  "name": "Pingliang City"},
            {"code": "620900",  "name": "Jiuquan City"},
            {"code": "621000",  "name": "Qingyang City"},
            {"code": "621100",  "name": "Dingxi City"},
            {"code": "621200",  "name": "Longnan City"},
            {"code": "622900",  "name": "Linxia Hui Autonomous Prefecture"},
            {"code": "623000",  "name": "Gannan Tibetan Autonomous Prefecture"}
        ]
    },
    {
        "code": "630000",
        "name": "Qinghai Province",
        "children": [
            {"code": "630100",  "name": "Xining City"},
            {"code": "632100",  "name": "Haidong District"},
            {"code": "632200",  "name": "Haibei Tibetan Autonomous Prefecture"},
            {"code": "632300",  "name": "Huangnan Tibetan Autonomous Prefecture"},
            {"code": "632500",  "name": "Hainan Tibetan Autonomous Prefecture"},
            {"code": "632600",  "name": "Golog Tibetan Autonomous Prefecture"},
            {"code": "632700",  "name": "Yushu Tibetan Autonomous Prefecture"},
            {"code": "632800",  "name": "Haixi Mongol and Tibetan Autonomous Prefecture"}
        ]
    },
    {
        "code": "640000",
        "name": "Ningxia Hui Autonomous Region",
        "children": [
            {"code": "640100",  "name": "Yinchuan City"},
            {"code": "640200",  "name": "Shizuishan City"},
            {"code": "640300",  "name": "Wuzhong City"},
            {"code": "640400",  "name": "Guyuan City"},
            {"code": "640500",  "name": "Zhongwei City"}
        ]
    },
    {
        "code": "650000",
        "name": "Xinjiang Uygur Autonomous Region",
        "children": [
            {"code": "650100", "name": "Ürümqi City"},
            {"code": "650200", "name": "Karamay City"},
            {"code": "652100", "name": "Turpan District"},
            {"code": "652200","name": "Hami District"},
            {"code": "652300",  "name": "Changji Hui Autonomous Prefecture"},
            {"code": "652700",  "name": "Bortala Mongol Autonomous Prefecture"},
            {"code": "652800",  "name": "Bayin'gholin Mongol Autonomous Prefecture"},
            {"code": "652900",  "name": "Aksu District"},
            {"code": "653000",  "name": "Kizilsu Kirghiz Autonomous Prefecture"},
            {"code": "653100",  "name": "Kashgar District"},
            {"code": "653200",  "name": "Hotan District"},
            {"code": "654000",  "name": "Ili Kazakh Autonomous Prefecture"},
            {"code": "654200",  "name": "Tacheng District"},
            {"code": "654300",  "name": "Altay District"}
        ]
    },
    {
        "code": "710000",
        "name": "Taiwan Province",
        "children": [
            {"code": "710000","name": "Taiwan Province"}
        ]
    },
    {
        "code": "810000",
        "name": "Hong Kong Special Administrative Region",
        "children": [
            {"code": "810000", "name": "Hong Kong Special Administrative Region"}
        ]
    },
    {
        "code": "820000",
        "name": "Macao Special Administrative Region",
        "children": [
            {"code": "820000", "name": "Macao Special Administrative Region"}
        ]
    }
]
